.bgimg {
    position: absolute;
    top: 0;
    bottom: 0;
    /* background-image: url("/img/boa.jpg"); */
    background-color: black;
    width: 100%;
    background-position: center;
    background-size: cover;
    font-family: "Courier New", Courier, monospace;
    font-size: 25px;
    color: #ffff;
}

.topleft {
    position: absolute;
    top: 0;
    left: 16px;
    width: 290px;
}

.topleft > img {
    position: relative;
    width: 100%;
    height: 80px;
}

.bottomleft {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
}

.middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.middle a {
    color: white;
    cursor: pointer;
}

hr {
    margin: auto;
    width: 40%;
}

h1 {
    line-height: initial;
}
p {
    line-height: initial;
}

/**/
